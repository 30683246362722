<div class="tabbed-header">
  <h1>{{ "changeMasterPassword" | i18n }}</h1>
</div>

<bit-callout type="warning">{{ "loggedOutWarning" | i18n }}</bit-callout>
<app-callout
  type="info"
  [enforcedPolicyOptions]="enforcedPolicyOptions"
  *ngIf="enforcedPolicyOptions"
>
</app-callout>

<form #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate autocomplete="off">
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="currentMasterPassword">{{ "currentMasterPass" | i18n }}</label>
        <input
          id="currentMasterPassword"
          type="password"
          name="MasterPasswordHash"
          class="form-control"
          [(ngModel)]="currentMasterPassword"
          required
          appInputVerbatim
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="newMasterPassword">{{ "newMasterPass" | i18n }}</label>
        <input
          id="newMasterPassword"
          type="password"
          name="NewMasterPasswordHash"
          class="form-control mb-1"
          [(ngModel)]="masterPassword"
          required
          appInputVerbatim
          autocomplete="new-password"
        />
        <app-password-strength
          [password]="masterPassword"
          [email]="email"
          [showText]="true"
          (passwordStrengthResult)="getStrengthResult($event)"
        >
        </app-password-strength>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label for="masterPasswordRetype">{{ "confirmNewMasterPass" | i18n }}</label>
        <input
          id="masterPasswordRetype"
          type="password"
          name="MasterPasswordRetype"
          class="form-control"
          [(ngModel)]="masterPasswordRetype"
          required
          appInputVerbatim
          autocomplete="new-password"
        />
      </div>
    </div>
  </div>
  <div class="form-group">
    <label for="masterPasswordHint">{{ "masterPassHintLabel" | i18n }}</label>
    <input
      id="masterPasswordHint"
      class="form-control"
      maxlength="50"
      type="text"
      name="MasterPasswordHint"
      [(ngModel)]="masterPasswordHint"
    />
  </div>
  <div class="form-group">
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        id="rotateEncKey"
        name="RotateEncKey"
        [(ngModel)]="rotateEncKey"
        (change)="rotateEncKeyClicked()"
      />
      <label class="form-check-label" for="rotateEncKey">
        {{ "rotateAccountEncKey" | i18n }}
      </label>
      <a
        href="https://bitwarden.com/help/account-encryption-key/#rotate-your-encryption-key"
        target="_blank"
        rel="noopener"
        appA11yTitle="{{ 'learnMore' | i18n }}"
      >
        <i class="bwi bwi-question-circle" aria-hidden="true"></i>
      </a>
    </div>
  </div>
  <button type="submit" buttonType="primary" bitButton [loading]="form.loading">
    {{ "changeMasterPassword" | i18n }}
  </button>
</form>
