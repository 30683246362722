<ng-container *ngIf="show">
  <ul class="filter-options">
    <li class="filter-option" [ngClass]="{ active: activeFilter.status === 'all' }">
      <span class="filter-buttons">
        <button class="filter-button" (click)="applyFilter('all')">
          <i class="bwi bwi-fw bwi-filter" aria-hidden="true"></i>&nbsp;{{ "allItems" | i18n }}
        </button>
      </span>
    </li>
    <li
      *ngIf="!hideFavorites"
      class="filter-option"
      [ngClass]="{ active: activeFilter.status === 'favorites' }"
    >
      <span class="filter-buttons">
        <button class="filter-button" (click)="applyFilter('favorites')">
          <i class="bwi bwi-fw bwi-star" aria-hidden="true"></i>&nbsp;{{ "favorites" | i18n }}
        </button>
      </span>
    </li>
    <li
      *ngIf="!hideTrash"
      class="filter-option"
      [ngClass]="{ active: activeFilter.status === 'trash' }"
    >
      <span class="filter-buttons">
        <button class="filter-button" (click)="applyFilter('trash')">
          <i class="bwi bwi-fw bwi-trash" aria-hidden="true"></i>&nbsp;{{ "trash" | i18n }}
        </button>
      </span>
    </li>
  </ul>
</ng-container>
