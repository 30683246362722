<div class="icon" aria-hidden="true">
  <img
    [src]="image"
    appFallbackSrc="{{ fallbackImage }}"
    *ngIf="imageEnabled && image"
    alt=""
    decoding="async"
    loading="lazy"
  />
  <i class="bwi bwi-fw bwi-lg {{ icon }}" *ngIf="!imageEnabled || !image"></i>
</div>
