<div class="modal fade" role="dialog" aria-modal="true" aria-labelledby="moveSelectedTitle">
  <div class="modal-dialog modal-dialog-scrollable modal-sm" role="document">
    <form class="modal-content" #form (ngSubmit)="submit()" [appApiAction]="formPromise">
      <div class="modal-header">
        <h1 class="modal-title" id="moveSelectedTitle">
          {{ "moveSelected" | i18n }}
        </h1>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          appA11yTitle="{{ 'close' | i18n }}"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{ "moveSelectedItemsDesc" | i18n: cipherIds.length }}</p>
        <div class="form-group">
          <label for="folder">{{ "folder" | i18n }}</label>
          <select id="folder" name="FolderId" [(ngModel)]="folderId" class="form-control">
            <option *ngFor="let f of folders$ | async" [ngValue]="f.id">{{ f.name }}</option>
          </select>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
          <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
          <span>{{ "save" | i18n }}</span>
        </button>
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">
          {{ "cancel" | i18n }}
        </button>
      </div>
    </form>
  </div>
</div>
