<ng-container *ngIf="!hide">
  <div class="filter-heading">
    <button
      class="toggle-button"
      (click)="toggleCollapse(foldersGrouping)"
      [attr.aria-expanded]="!isCollapsed(foldersGrouping)"
      aria-controls="folder-filters"
      title="{{ 'toggleCollapse' | i18n }}"
    >
      <i
        class="bwi bwi-fw"
        aria-hidden="true"
        [ngClass]="{
          'bwi-angle-right': isCollapsed(foldersGrouping),
          'bwi-angle-down': !isCollapsed(foldersGrouping)
        }"
      ></i>
    </button>
    <h3 class="filter-title">&nbsp;{{ "folders" | i18n }}</h3>
    <button
      class="text-muted ml-auto add-button"
      (click)="addFolder()"
      appA11yTitle="{{ 'addFolder' | i18n }}"
    >
      <i class="bwi bwi-plus bwi-fw" aria-hidden="true"></i>
    </button>
  </div>
  <ul id="folder-filters" *ngIf="!isCollapsed(foldersGrouping)" class="filter-options">
    <ng-template #recursiveFolders let-folders>
      <li
        *ngFor="let f of folders"
        [ngClass]="{
          active: f.node.id === activeFilter.selectedFolderId && activeFilter.selectedFolder
        }"
        class="filter-option"
      >
        <span class="filter-buttons">
          <button
            *ngIf="f.children.length"
            title="{{ 'toggleCollapse' | i18n }}"
            (click)="toggleCollapse(f.node)"
            [attr.aria-expanded]="!isCollapsed(f.node)"
            [attr.aria-controls]="f.node.name + '_children'"
            class="toggle-button"
          >
            <i
              class="bwi bwi-fw"
              [ngClass]="{
                'bwi-angle-right': isCollapsed(f.node),
                'bwi-angle-down': !isCollapsed(f.node)
              }"
              aria-hidden="true"
            ></i>
          </button>
          <button class="filter-button" (click)="applyFilter(f.node)">
            <i *ngIf="f.children.length === 0" class="bwi bwi-fw bwi-folder" aria-hidden="true"></i
            >&nbsp;{{ f.node.name }}
          </button>
          <button
            class="edit-button"
            (click)="editFolder(f.node)"
            appA11yTitle="{{ 'editFolder' | i18n }}"
            *ngIf="f.node.id"
          >
            <i class="bwi bwi-pencil bwi-fw" aria-hidden="true"></i>
          </button>
        </span>
        <ul
          [id]="f.node.name + '_children'"
          class="nested-filter-options"
          *ngIf="f.children.length && !isCollapsed(f.node)"
        >
          <ng-container *ngTemplateOutlet="recursiveFolders; context: { $implicit: f.children }">
          </ng-container>
        </ul>
      </li>
    </ng-template>
    <ng-container
      *ngTemplateOutlet="recursiveFolders; context: { $implicit: nestedFolders }"
    ></ng-container>
  </ul>
</ng-container>
