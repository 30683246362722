<div class="container page-content">
  <div class="row">
    <div class="col-3">
      <div class="groupings">
        <div class="content">
          <div class="inner-content">
            <app-organization-vault-filter
              #vaultFilter
              [activeFilter]="activeFilter"
              (onFilterChange)="applyVaultFilter($event)"
              (onSearchTextChanged)="filterSearchText($event)"
            ></app-organization-vault-filter>
          </div>
        </div>
      </div>
    </div>
    <div class="col-9">
      <div class="page-header d-flex">
        <h1>
          {{ "vaultItems" | i18n }}
          <small #actionSpinner [appApiAction]="ciphersComponent.actionPromise">
            <ng-container *ngIf="actionSpinner.loading">
              <i
                class="bwi bwi-spinner bwi-spin text-muted"
                title="{{ 'loading' | i18n }}"
                aria-hidden="true"
              ></i>
              <span class="sr-only">{{ "loading" | i18n }}</span>
            </ng-container>
          </small>
        </h1>
        <div class="ml-auto d-flex">
          <app-vault-bulk-actions
            [ciphersComponent]="ciphersComponent"
            [deleted]="deleted"
            [organization]="organization"
          >
          </app-vault-bulk-actions>
          <button
            type="button"
            class="btn btn-outline-primary btn-sm ml-auto"
            (click)="addCipher()"
            *ngIf="!deleted"
          >
            <i class="bwi bwi-plus bwi-fw" aria-hidden="true"></i>{{ "addItem" | i18n }}
          </button>
        </div>
      </div>
      <app-callout type="warning" *ngIf="deleted" icon="bwi bwi-exclamation-triangle">
        {{ trashCleanupWarning }}
      </app-callout>
      <app-org-vault-ciphers
        (onCipherClicked)="editCipher($event)"
        (onAttachmentsClicked)="editCipherAttachments($event)"
        (onAddCipher)="addCipher()"
        (onCollectionsClicked)="editCipherCollections($event)"
        (onEventsClicked)="viewEvents($event)"
        (onCloneClicked)="cloneCipher($event)"
      >
      </app-org-vault-ciphers>
    </div>
  </div>
</div>
<ng-template #attachments></ng-template>
<ng-template #cipherAddEdit></ng-template>
<ng-template #collections></ng-template>
<ng-template #eventsTemplate></ng-template>
