<div class="modal fade" role="dialog" aria-modal="true" aria-labelledby="groupAccessTitle">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <form class="modal-content" #form (ngSubmit)="submit()" [appApiAction]="formPromise">
      <div class="modal-header">
        <h1 class="modal-title" id="groupAccessTitle">
          {{ "groupAccess" | i18n }}
          <small class="text-muted" *ngIf="name">{{ name }}</small>
        </h1>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          appA11yTitle="{{ 'close' | i18n }}"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="loading">
        <i
          class="bwi bwi-spinner bwi-spin text-muted"
          title="{{ 'loading' | i18n }}"
          aria-hidden="true"
        ></i>
        <span class="sr-only">{{ "loading" | i18n }}</span>
      </div>
      <div class="modal-body" *ngIf="!loading">
        <p>{{ "groupAccessUserDesc" | i18n }}</p>
        <div *ngIf="!groups || !groups.length">
          {{ "noGroupsInList" | i18n }}
        </div>
        <table class="table table-hover table-list mb-0" *ngIf="groups && groups.length">
          <tbody>
            <tr *ngFor="let g of groups; let i = index">
              <td class="table-list-checkbox" (click)="check(g)">
                <input
                  type="checkbox"
                  [(ngModel)]="g.checked"
                  name="Groups[{{ i }}].Checked"
                  appStopProp
                />
              </td>
              <td (click)="check(g)">
                {{ g.name }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
          <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
          <span>{{ "save" | i18n }}</span>
        </button>
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">
          {{ "cancel" | i18n }}
        </button>
      </div>
    </form>
  </div>
</div>
