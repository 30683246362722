<div class="filter-heading">
  <button
    class="toggle-button"
    [attr.aria-expanded]="!isCollapsed"
    aria-controls="type-filters"
    (click)="toggleCollapse()"
    title="{{ 'toggleCollapse' | i18n }}"
  >
    <i
      class="bwi bwi-fw"
      aria-hidden="true"
      [ngClass]="{
        'bwi-angle-right': isCollapsed,
        'bwi-angle-down': !isCollapsed
      }"
    ></i>
  </button>
  <h3>&nbsp;{{ "types" | i18n }}</h3>
</div>
<ul id="type-filters" *ngIf="!isCollapsed" class="filter-options">
  <li
    class="filter-option"
    [ngClass]="{ active: activeFilter.cipherType === cipherTypeEnum.Login }"
  >
    <span class="filter-buttons">
      <button class="filter-button" (click)="applyFilter(cipherTypeEnum.Login)">
        <i class="bwi bwi-fw bwi-globe" aria-hidden="true"></i>&nbsp;{{ "typeLogin" | i18n }}
      </button>
    </span>
  </li>
  <li class="filter-option" [ngClass]="{ active: activeFilter.cipherType === cipherTypeEnum.Card }">
    <span class="filter-buttons">
      <button class="filter-button" (click)="applyFilter(cipherTypeEnum.Card)">
        <i class="bwi bwi-fw bwi-credit-card" aria-hidden="true"></i>&nbsp;{{ "typeCard" | i18n }}
      </button>
    </span>
  </li>
  <li
    class="filter-option"
    [ngClass]="{ active: activeFilter.cipherType === cipherTypeEnum.Identity }"
  >
    <span class="filter-buttons">
      <button class="filter-button" (click)="applyFilter(cipherTypeEnum.Identity)">
        <i class="bwi bwi-fw bwi-id-card" aria-hidden="true"></i>&nbsp;{{ "typeIdentity" | i18n }}
      </button>
    </span>
  </li>
  <li
    class="filter-option"
    [ngClass]="{ active: activeFilter.cipherType === cipherTypeEnum.SecureNote }"
  >
    <span class="filter-buttons">
      <button class="filter-button" (click)="applyFilter(cipherTypeEnum.SecureNote)">
        <i class="bwi bwi-fw bwi-sticky-note" aria-hidden="true"></i>&nbsp;{{
          "typeSecureNote" | i18n
        }}
      </button>
    </span>
  </li>
</ul>
