<form
  #form
  (ngSubmit)="submit()"
  [appApiAction]="formPromise"
  class="tw-container tw-mx-auto"
  [formGroup]="formGroup"
>
  <div>
    <div class="tw-mb-3">
      <bit-form-field>
        <bit-label>{{ "emailAddress" | i18n }}</bit-label>
        <input id="register-form_input_email" bitInput type="email" formControlName="email" />
        <bit-hint>{{ "emailAddressDesc" | i18n }}</bit-hint>
      </bit-form-field>
    </div>

    <div class="tw-mb-3">
      <bit-form-field>
        <bit-label>{{ "name" | i18n }}</bit-label>
        <input id="register-form_input_name" bitInput type="text" formControlName="name" />
        <bit-hint>{{ "yourNameDesc" | i18n }}</bit-hint>
      </bit-form-field>
    </div>

    <div class="tw-mb-3">
      <app-callout
        type="info"
        [enforcedPolicyOptions]="enforcedPolicyOptions"
        *ngIf="enforcedPolicyOptions"
      >
      </app-callout>
      <bit-form-field>
        <bit-label>{{ "masterPass" | i18n }}</bit-label>
        <input
          id="register-form_input_master-password"
          bitInput
          type="{{ showPassword ? 'text' : 'password' }}"
          formControlName="masterPassword"
        />
        <button type="button" bitSuffix bitButton (click)="togglePassword()">
          <i
            aria-hidden="true"
            class="bwi bwi-lg bwi-eye"
            [ngClass]="{ 'bwi-eye': !showPassword, 'bwi-eye-slash': showPassword }"
          ></i>
        </button>
        <bit-hint>
          <span class="tw-font-semibold">Important:</span>
          {{ "masterPassImportant" | i18n }}
        </bit-hint>
      </bit-form-field>
      <app-password-strength
        [password]="formGroup.get('masterPassword')?.value"
        [email]="formGroup.get('email')?.value"
        [name]="formGroup.get('name')?.value"
        [showText]="true"
        (passwordStrengthResult)="getStrengthResult($event)"
      >
      </app-password-strength>
    </div>

    <div class="tw-mb-3">
      <bit-form-field>
        <bit-label>{{ "reTypeMasterPass" | i18n }}</bit-label>
        <input
          id="register-form_input_confirm-master-password"
          bitInput
          type="{{ showPassword ? 'text' : 'password' }}"
          formControlName="confirmMasterPassword"
        />
        <button type="button" bitSuffix bitButton (click)="togglePassword()">
          <i
            aria-hidden="true"
            class="bwi bwi-lg bwi-eye"
            [ngClass]="{ 'bwi-eye': !showPassword, 'bwi-eye-slash': showPassword }"
          ></i>
        </button>
      </bit-form-field>
    </div>

    <div class="tw-mb-3">
      <bit-form-field>
        <bit-label>{{ "masterPassHint" | i18n }}</bit-label>
        <input id="register-form_input_hint" bitInput type="text" formControlName="hint" />
        <bit-hint>{{ "masterPassHintDesc" | i18n }}</bit-hint>
      </bit-form-field>
    </div>

    <div [hidden]="!showCaptcha()">
      <iframe id="hcaptcha_iframe" height="80"></iframe>
    </div>

    <div class="tw-mb-3 tw-flex tw-items-start" *ngIf="showTerms">
      <div class="tw-flex tw-h-6 tw-items-center">
        <input
          id="register-form_input_accept-policies"
          class="tw-border-gray-300 tw-w-4 tw-rounded tw-border"
          bitInput
          type="checkbox"
          formControlName="acceptPolicies"
        />
      </div>
      <bit-label class="ml-2">
        {{ "acceptPolicies" | i18n }}<br />
        <a href="https://bitwarden.com/terms/" target="_blank" rel="noopener">{{
          "termsOfService" | i18n
        }}</a
        >,
        <a href="https://bitwarden.com/privacy/" target="_blank" rel="noopener">{{
          "privacyPolicy" | i18n
        }}</a>
      </bit-label>
    </div>

    <div class="tw-mb-3 tw-flex">
      <ng-container *ngIf="!accountCreated">
        <button type="submit" buttonType="primary" bitButton [loading]="form.loading">
          {{ "createAccount" | i18n }}
        </button>
        <a
          bitButton
          buttonType="secondary"
          routerLink="/login"
          class="tw-ml-3 tw-inline-flex tw-items-center tw-px-3"
        >
          <i class="bwi bwi-sign-in tw-mr-2"></i>
          {{ "logIn" | i18n }}
        </a>
      </ng-container>
      <ng-container *ngIf="accountCreated">
        <button type="submit" buttonType="primary" bitButton [loading]="form.loading">
          {{ "logIn" | i18n }}
        </button>
      </ng-container>
    </div>
    <bit-error-summary *ngIf="showErrorSummary" [formGroup]="formGroup"></bit-error-summary>
  </div>
</form>
