<td>
  {{ sponsoringOrg.familySponsorshipFriendlyName }}
</td>
<td>{{ sponsoringOrg.name }}</td>
<td>
  <span [ngClass]="statusClass">{{ statusMessage }}</span>
</td>
<td class="table-action-right">
  <div class="dropdown" appListDropdown>
    <button
      *ngIf="!sponsoringOrg.familySponsorshipToDelete"
      class="btn btn-outline-secondary dropdown-toggle"
      type="button"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      appA11yTitle="{{ 'options' | i18n }}"
    >
      <i class="bwi bwi-cog bwi-lg" aria-hidden="true"></i>
    </button>
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
      <button
        #resendEmailBtn
        *ngIf="!isSelfHosted && !sponsoringOrg.familySponsorshipValidUntil"
        [appApiAction]="resendEmailPromise"
        class="dropdown-item btn-submit"
        [disabled]="resendEmailBtn.loading"
        (click)="resendEmail()"
        [attr.aria-label]="'resendEmailLabel' | i18n: sponsoringOrg.familySponsorshipFriendlyName"
      >
        <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
        <span>{{ "resendEmail" | i18n }}</span>
      </button>
      <button
        #revokeSponsorshipBtn
        [appApiAction]="revokeSponsorshipPromise"
        class="dropdown-item text-danger btn-submit"
        [disabled]="revokeSponsorshipBtn.loading"
        (click)="revokeSponsorship()"
        [attr.aria-label]="'revokeAccount' | i18n: sponsoringOrg.familySponsorshipFriendlyName"
      >
        <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
        <span>{{ "remove" | i18n }}</span>
      </button>
    </div>
  </div>
</td>
