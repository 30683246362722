<ng-container *ngIf="show">
  <div class="filter-heading">
    <button
      (click)="toggleCollapse(collectionsGrouping)"
      [attr.aria-expanded]="!isCollapsed(collectionsGrouping)"
      aria-controls="collection-filters"
      title="{{ 'toggleCollapse' | i18n }}"
      class="toggle-button"
    >
      <i
        class="bwi bwi-fw"
        [ngClass]="{
          'bwi-angle-right': isCollapsed(collectionsGrouping),
          'bwi-angle-down': !isCollapsed(collectionsGrouping)
        }"
        aria-hidden="true"
      ></i>
    </button>
    <h3 class="filter-title">&nbsp;{{ collectionsGrouping.name | i18n }}</h3>
  </div>
  <ul id="collection-filters" *ngIf="!isCollapsed(collectionsGrouping)" class="filter-options">
    <ng-template #recursiveCollections let-collections>
      <li
        *ngFor="let c of collections"
        [ngClass]="{
          active: c.node.id === activeFilter.selectedCollectionId && activeFilter.selectedCollection
        }"
        class="filter-option"
      >
        <span class="filter-buttons">
          <button
            class="toggle-button"
            *ngIf="c.children.length"
            (click)="toggleCollapse(c.node)"
            title="{{ 'toggleCollapse' | i18n }}"
            [attr.aria-expanded]="!isCollapsed(c.node)"
            [attr.aria-controls]="c.node.name + '_children'"
          >
            <i
              class="bwi bwi-fw"
              [ngClass]="{
                'bwi-angle-right': isCollapsed(c.node),
                'bwi-angle-down': !isCollapsed(c.node)
              }"
              aria-hidden="true"
            ></i>
          </button>
          <button class="filter-button" (click)="applyFilter(c.node)">
            <i
              *ngIf="c.children.length === 0"
              class="bwi bwi-collection bwi-fw"
              aria-hidden="true"
            ></i
            >&nbsp;{{ c.node.name }}
          </button>
        </span>
        <ul
          [id]="c.node.name + '_children'"
          class="nested-filter-options"
          *ngIf="c.children.length && !isCollapsed(c.node)"
        >
          <ng-container
            *ngTemplateOutlet="recursiveCollections; context: { $implicit: c.children }"
          >
          </ng-container>
        </ul>
      </li>
    </ng-template>
    <ng-container
      *ngTemplateOutlet="recursiveCollections; context: { $implicit: nestedCollections }"
    >
    </ng-container>
  </ul>
</ng-container>
