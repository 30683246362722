<div *ngIf="loading">
  <i
    class="bwi bwi-spinner bwi-spin text-muted"
    title="{{ 'loading' | i18n }}"
    aria-hidden="true"
  ></i>
  <span class="sr-only">{{ "loading" | i18n }}</span>
</div>
<form
  *ngIf="profile && !loading"
  #form
  (ngSubmit)="submit()"
  [appApiAction]="formPromise"
  ngNativeValidate
>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="name">{{ "name" | i18n }}</label>
        <input id="name" class="form-control" type="text" name="Name" [(ngModel)]="profile.name" />
      </div>
      <div class="form-group">
        <label for="email">{{ "email" | i18n }}</label>
        <input
          id="email"
          class="form-control"
          type="text"
          name="Email"
          [(ngModel)]="profile.email"
          readonly
        />
      </div>
    </div>
    <div class="col-6">
      <div class="mb-3">
        <bit-avatar [text]="profile | userName" [id]="profile.id" size="large"></bit-avatar>
      </div>
      <hr />
      <p *ngIf="fingerprint">
        {{ "yourAccountsFingerprint" | i18n }}:
        <a
          href="https://bitwarden.com/help/fingerprint-phrase/"
          target="_blank"
          rel="noopener"
          appA11yTitle="{{ 'learnMore' | i18n }}"
        >
          <i class="bwi bwi-question-circle" aria-hidden="true"></i></a
        ><br />
        <code>{{ fingerprint }}</code>
      </p>
    </div>
  </div>
  <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
    <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
    <span>{{ "save" | i18n }}</span>
  </button>
</form>
